import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import openGpaphImage from "../images/opengraph/main.jpg"


function CookiesPolicy () {

  const analyticsCookieArr = ['_ga', '_ga_Y88LXDWDCP']
  const thirdPartyCookieArr = ['_cltk', '_clck', '_clsk']
  const isBrowser = typeof window !== "undefined"

  const [needUpdate, setNeedUpdate] = useState(false);
  const [cookieConcents, setCookieConcents] = useState(false);
  const [isPreferences2, setIsPreferences2] = useState('');
  const [isAnalytics2, setIsAnalytics2] = useState('');
  const [isThirdParty2, setIsThirdParty2] = useState('');

  useEffect(() => {
    setCookieConcents(localStorage.getItem('cookieConcents') ? true : false)
  })

  useEffect(() => {
    if (cookieConcents) {
      const cookieRules = JSON.parse(localStorage.getItem('cookieConcents'));
      setIsPreferences2(cookieRules['preferences'])
      setIsAnalytics2(cookieRules['analytics'])
      setIsThirdParty2(cookieRules['thirdParty'])
    }
  }, [cookieConcents])

  // detects changes in local storage when user sets cookie from modal window (if it is his first visit and local storage is empty)
  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('change-local-storage', () => {
        setCookieConcents(JSON.parse(localStorage.getItem('cookieConcents')));
      })
    }
  })

  const handlePreferencesCookie2 = (e) => {
    setIsPreferences2(!isPreferences2);
    setNeedUpdate(!needUpdate);
  }

  const handleAnalyticsCookie2 = (e) => {
    setIsAnalytics2(!isAnalytics2);
    setNeedUpdate(!needUpdate);
    if(isAnalytics2) {
      analyticsCookieArr.forEach(e => delete_cookie(e))
    }
  }

  const handleThirdPartyCookie2 = (e) => {
    setIsThirdParty2(!isThirdParty2);
    setNeedUpdate(!needUpdate);
    if(isThirdParty2) {
      thirdPartyCookieArr.forEach(e => delete_cookie(e))
    }
  }

  useEffect(() => {
    if (cookieConcents) {
      let cookieObj = {
        'function': true,
        'preferences': isPreferences2,
        'analytics': isAnalytics2,
        'thirdParty': isThirdParty2
      }
      localStorage.setItem('cookieConcents', JSON.stringify(cookieObj));
    }
  }, [needUpdate])

  const delete_cookie = (name) => {
    document.cookie = name +'=; Domain=.iamcore.io; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };

  return (
    <Layout>

    <section className="static-content">
      <div className="container">
        <div className="row">
            <div className="col-lg-8 offset-lg-2 col-12">
                <h1>Cookies Policy</h1>
                <div className="row row-subsection">
                  <div className="col-12">
                    <p>
                    iamcore (“iamcore”, “Us”, “Our”, or “We”) has created this Cookies Policy describing what cookies are, how We use cookies, how to modify Your browser preferences to disable cookies, and what cookies We use.
                    </p>
                    <p>
                    Please read this Cookies Policy in conjunction with Our Privacy Policy, which sets out additional details on how We use personally identifiable information and Your various rights.
                    </p>

                    <h2>What Are Cookies</h2>
                    <p>
                    An "Internet cookie" (the term known as a "browser cookie", or an "HTTP cookie", or simply a "cookie") is a small file consisting of letters and numbers that is stored on Your computer, handheld device, or other equipment that You use to access the Internet.
                    </p>
                    <p>
                    The cookie is installed by request issued by a server to a web browser (e.g. Internet Explorer, Firefox, Chrome) and is completely "passive" (does not contain software viruses or spyware and cannot access the information on the user’s devices). Cookies store information in a small text file that allows various website functionality to work correctly. The web browser will recognize cookies until they expire or are deleted.
                    </p>
                    <p>
                    In what is known as “Web 2.0”, "cookies" play an important role in facilitating access and delivery of multiple services over the Internet, such as customizing the version of the Sites based on the user’s current device type. Cookies offer valuable feedback on how websites are used so that they could be made more efficient and accessible to users. Cookies also enable multimedia and other applications on other websites to be included into a particular website to create a richer browsing experience.
                    </p>
                    <h2>How We Use Cookies</h2>
                    <p>
                    Our Sites, like many others, store and retrieve information in Your browser by using cookies. This information is used to make the Sites work as You expect it to. Cookies can be used to give You a more personalized web experience.
                    </p>
                    
                    <h2>How to Modify Your Browser Preferences to Disable Cookies</h2>
                    <p>
                    Most web browsers allow control of most cookies through the browser settings.
                    </p>
                    <p>
                    Please note, however, that if You delete cookies or refuse to accept them, You might not be able to use some or all of the features We offer. You may not be able to log in, store Your preferences, and some of Our Sites’ pages might not display properly.
                    </p>
                    <h2>What Cookies We Use</h2>
                    <p>Below is an up to date list of the cookies We use on Our Sites.</p>
                  </div>
                </div>

                <div className="row row-subsection">
                  <div className="col-12"><hr /></div>
                  <div className="col-12 text-wrapper">
                    <h2>Functional cookies</h2>
                    <p>These cookies are necessary for the Sites to function and cannot be switched off in Our systems. They are usually only set in response to actions made by You that amount to a request for services, such as setting Your preferences, logging in, or filling out forms. You can set Your browser to block or alert You about these cookies, however, in this case some parts of the Sites will not work.</p>    
                  </div>
                </div>
                
                <div className="row row-subsection">
                  <div className="col-12"><hr /></div>
                  <div className="col-12 text-wrapper">
                    <div className="row">
                      <div className="col-md-11 col-9">
                        <h2>Preferences</h2>
                      </div>
                      <div className="col-md-1 col-3 custom-check-wrapper">
                        <input
                          type="checkbox"
                          value={isPreferences2}
                          checked = {isPreferences2 ? true : false }
                          onChange={handlePreferencesCookie2}
                          id="preferences2"
                          name="preferences2"
                        />
                        <label htmlFor="preferences2">
                        Preferences
                        </label>
                      </div>
                      <div className="col-12">
                        { !isPreferences2 ? 
                        <p><span className="danger-p">By blocking this feature, you restrict our legitimate right to assess the efficiency of our business promotion and analyse the quality of our services, and therefore waive our responsibility for your secure use of the website.</span></p>
                        : null }
                        <p>Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.</p>   
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="row row-subsection">
                  <div className="col-12"><hr /></div>
                  <div className="col-12 text-wrapper">
                    <div className="row">
                      <div className="col-md-11 col-9">
                        <h2>Statistics</h2>
                      </div>
                      <div className="col-md-1 col-3 custom-check-wrapper">
                          <input
                              type="checkbox"
                              value={isAnalytics2}
                              checked = {isAnalytics2 ? true : false }
                              onChange={handleAnalyticsCookie2}
                              id="analytics2"
                              name="analytics2"
                          />
                          <label htmlFor="analytics2">
                          Analytics
                          </label>
                      </div>
                      <div className="col-12">
                      { !isAnalytics2 ? 
                      <p><span className="danger-p">By blocking this feature, you restrict our legitimate right to assess the efficiency of our business promotion and analyse the quality of our services, and therefore waive our responsibility for your secure use of the website.</span></p>
                      : null }

                      <p>Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.</p> 

                        <div className="table-responsive">
                            <table className="table">
                            <thead className="table-dark">
                                <tr>
                                <th >COOKIE NAME</th>
                                <th >PURPOSE</th>
                                <th >EXPIRATION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="w-50">
                                <td className="w-25">_ga</td>
                                <td>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</td>
                                <td>2 years</td>
                                </tr>
                                <tr className="w-50">
                                <td className="w-25">_ga_Y88LXDWDCP</td>
                                <td>This cookie is stored by Google Analytics and is used to store information on how visitors use a website and helps create analytics reports on website usage.</td>
                                <td>2 years</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                

                <div className="row row-subsection">
                  <div className="col-12 text-wrapper">
                    <div className="row">
                      <div className="col-md-11 col-9">
                        <h2>Third-Party Cookies</h2>
                      </div>
                      <div className="col-md-1 col-3 custom-check-wrapper">
                          <input
                              type="checkbox"
                              value={isThirdParty2}
                              onChange={handleThirdPartyCookie2}
                              checked = {isThirdParty2 ? true : false }
                              id="thirdParty2"
                              name="thirdParty2"
                          />
                          <label htmlFor="thirdParty2">
                          ThirdParty
                          </label>
                      </div>
                      <div className="col-12">
                      { !isThirdParty2 ? 
                        <p><span className="danger-p">By blocking this feature, you restrict our legitimate right to assess the efficiency of our business promotion and analyse the quality of our services, and therefore waive our responsibility for your secure use of the website.</span></p>
                      : null }

                        <br /><br />
                        <h3>Google Analytics & Google Optimize Cookie</h3>
                        <br />
                        <div className="table-responsive">
                            <table className="table">
                            <thead className="table-dark">
                                <tr>
                                <th >COOKIE NAME</th>
                                <th >PURPOSE</th>
                                <th >EXPIRATION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="w-50">
                                <td className="w-25">_gaexp</td>
                                  <td>Used to determine a user's inclusion in an experiment and the expiry of experiments a user has been included in.</td>
                                  <td>Depends on the length of the experiment, but typically 90 days</td>
                                </tr>
                                <tr className="w-50">
                                  <td className="w-25">_gaexp_rc</td>
                                  <td>This cookie is set by Google Analytics and is used to determine a user's inclusion in an experiment and the expiry of experiments that a user has been included in.</td>
                                  <td>10 seconds</td>
                                </tr>
                                <tr className="w-50">
                                  <td className="w-25">_opt_awcid</td>
                                  <td>Used for campaigns mapped to Google Ads Customer IDs.</td>
                                  <td>24 hours</td>
                                </tr>
                                <tr className="w-50">
                                  <td className="w-25">_opt_awmid
                                  </td>
                                  <td>Used for campaigns mapped to Google Ads Campaign IDs</td>
                                  <td>24 hours</td>
                                </tr>
                                <tr className="w-50">
                                  <td className="w-25">_opt_awgid
                                  </td>
                                  <td>Used for campaigns mapped to Google Ads Ad Group IDs</td>
                                  <td>24 hours</td>
                                </tr>
                                <tr className="w-50">
                                  <td className="w-25">_opt_awkid
                                  </td>
                                  <td>Used for campaigns mapped to Google Ads Criterion IDs</td>
                                  <td>24 hours</td>
                                </tr>
                                <tr className="w-50">
                                  <td className="w-25">_opt_utmc
                                  </td>
                                  <td>Stores the last utm_campaign query parameter</td>
                                  <td>24 hours</td>
                                </tr>
                                <tr className="w-50">
                                  <td className="w-25">_opt_expid</td>
                                  <td>This cookie is created when running a redirect experiment. It stores the experiment ID, the variant ID and the referrer to the page that's being redirected</td>
                                  <td>10 seconds</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>

                        <br /><br />
                        <h3>Microsoft Clarity Cookies</h3>
                        <br />
                        <div className="table-responsive">
                            <table className="table">
                            <thead className="table-dark">
                                <tr>
                                <th >COOKIE NAME</th>
                                <th >PURPOSE</th>
                                <th >EXPIRATION, VALUE PASSED</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="w-50">
                                <td className="w-25">_cltk</td>
                                <td>This cookie is installed by Microsoft Clarity tool and stores information about how visitors use the website</td>
                                <td>session</td>
                                </tr>
                                <tr className="w-50">
                                <td className="w-25">_clck</td>
                                <td>Persists the Clarity User ID and preferences, unique to that site, on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID.</td>
                                <td>string</td>
                                </tr>
                                <tr className="w-50">
                                <td className="w-25">_clsk</td>
                                <td>	Connects multiple page views by a single user into a single Clarity session recording.</td>
                                <td>string</td>
                                </tr>  
                                <tr className="w-50">
                                <td className="w-25">CLID</td>
                                <td>Identifies the first-time Clarity saw this user on any site using Clarity </td>
                                <td>String</td>
                                </tr>
                                <tr className="w-50">
                                <td className="w-25">ANONCHK
                                </td>
                                <td>Indicates whether MUID is transferred to ANID, a cookie used for advertising. Clarity doesn’t use ANID and so this is always set to 0</td>
                                <td>Flag</td>
                                </tr>
                                <tr className="w-50">
                                <td className="w-25">MR
                                </td>
                                <td>Indicates whether to refresh MUID</td>
                                <td>Flag</td>
                                </tr>
                                <tr className="w-50">
                                <td className="w-25">MUID
                                </td>
                                <td>Identifies unique web browsers visiting Microsoft sites. These cookies are used for advertising, site analytics, and other operational purposes</td>
                                <td>GUID</td>
                                </tr>
                                <tr className="w-50">
                                <td className="w-25">SM
                                </td>
                                <td>Used in synchronizing the MUID across Microsoft domains</td>
                                <td>Character Flags</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>


                        <br /><br />
                        <h3>Meta/Facebook Pixel</h3>
                        <br />
                        <div className="table-responsive">
                            <table className="table">
                            <thead className="table-dark">
                                <tr>
                                <th >COOKIE NAME</th>
                                <th >PURPOSE</th>
                                <th >EXPIRATION, VALUE PASSED</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr className="w-50">
                              <td className="w-25">fbevents.js</td>
                              <td>This Cookie is placed by Facebook. It enables us to measure, optimize and build audiences for advertising campaigns served on Facebook.
                              To opt-out please <a href="https://www.facebook.com/ads/preferences" class="link--blue">see</a></td>
                              <td></td>
                              </tr>
                              <tr className="w-50">
                              <td className="w-25">_fbp</td>
                              <td>This cookie is used to provide you with more relevant advertising across Facebook</td>
                              <td>90 days</td>
                              </tr>
                              <tr className="w-50">
                              <td className="w-25">fr</td>
                              <td>This cookie is used to provide you with more relevant advertising across Facebook</td>
                              <td>90 days</td>
                              </tr>
                            </tbody>
                            </table>
                        </div>


                        <br /><br />
                        <h3>Linkedin Pixel</h3>
                        <br />
                        <div className="table-responsive">
                            <table className="table">
                            <thead className="table-dark">
                                <tr>
                                <th >COOKIE NAME</th>
                                <th >PURPOSE</th>
                                <th >EXPIRATION, VALUE PASSED</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr className="w-50">
                                <td className="w-25">bcookie</td>
                                <td>Browser identifier cookie to uniquely identify devices accessing Linked to detect abuse on the platform</td>
                                <td>2 years</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">BizoData</td>
                                <td>Used by LinkedIn Insight Tag to enable in-depth campaign reporting and discover new business demographics by layering LinkedIn data to website visitors data</td>
                                <td>180 days</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">BizoID</td>
                                <td>Used by LinkedIn Insight Tag to enable in-depth campaign reporting and discover new business demographics by layering LinkedIn data to website visitors data</td>
                                <td>180 days</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">BizoID</td>
                                <td>Used by LinkedIn Insight Tag to enable in-depth campaign reporting and discover new business demographics by layering LinkedIn data to website visitors data</td>
                                <td>180 days</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">BizoNetworkPartnerIndex</td>
                                <td>Used by LinkedIn Insight Tag to enable in-depth campaign reporting and discover new business demographics by layering LinkedIn data to website visitors data</td>
                                <td>180 days</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">bscookie</td>
                                <td>Secure browser cookie</td>
                                <td>2 years</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">cf_ob_info</td>
                                <td>Used by Cloudflare to track the request path and status through Cloudflare network to end servers for security purpose</td>
                                <td>3 days</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">cf_use_ob</td>
                                <td>Used by Cloudflare in the event servers are down, for showing a cached version of any requested web page</td>
                                <td>3 days</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">G_ENABLED_IDPS</td>
                                <td>Used by Google Single Sign On</td>
                                <td>2 years</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">lang</td>
                                <td>Used to remember a user’s language setting</td>
                                <td>session</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">LC1</td>
                                <td>This cookies enables us to optimize and build audiences for advertising campaigns served on Linkedin. In particular it enables us to see how our users move between devices when accessing our website and Linkedin, to ensure our Linkedin ads are seen by users most likely to be interested in such advertising by analyzing which content a user has viewed and interacted with on our website. 
                                To opt-out please <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" class="link--blue">see</a></td>
                                <td></td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">li_at</td>
                                <td>Used to authenticated members and API clients</td>
                                <td></td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">li_sugr</td>
                                <td>Used by LinkedIn Insight Tag for Browser Identifier</td>
                                <td>90 days</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">lidc</td>
                                <td>Cookie used for Sign-in with Linkedin and/or for Linkedin follow feature</td>
                                <td>1 day</td>
                              </tr>
                            </tbody>
                            </table>
                        </div>


                        <br /><br />
                        <h3>Twitter Pixel</h3>
                        <br />
                        <div className="table-responsive">
                            <table className="table">
                            <thead className="table-dark">
                                <tr>
                                <th >COOKIE NAME</th>
                                <th >PURPOSE</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr className="w-50">
                                <td className="w-25">csrf_same_site</td>
                                <td>These are Twitter cookies that are used on websites with Twitter feeds and / or Twitter sharing functionalities. For more information, please see <a href="https://help.twitter.com/en/rules-and-policies/twitter-cookies" class="link--blue">see</a></td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">csrf_same_site_set</td>
                                <td>These are Twitter cookies that are used on websites with Twitter feeds and / or Twitter sharing functionalities. For more information, please <a href="https://help.twitter.com/en/rules-and-policies/twitter-cookies" class="link--blue">see</a></td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">kdt</td>
                                <td>This cookie is generally provided by Twitter to monitor referral links and login status. They are usually present when there is a "Tweet This" button or other Twitter widget present on the site.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">remember_checked_on</td>
                                <td>This cookie is generally provided by Twitter to monitor referral links and login status. They are usually present when there is a "Tweet This" button or other Twitter widget present on the site.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">twid</td>
                                <td>Cookie set due to Twitter integration and sharing capabilities in the social media</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">ads_prefs</td>
                                <td>These cookies enable us to track visitor activity from our Twitter ads on our website, and also to allow users to share content from our websites. They cookies do not provide us with any confidential information relating to your account.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">muc_ads</td>
                                <td>Collects data on user behaviour and interaction in order to optimize the website and make advertisement on the website more relevant.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">guest_id</td>
                                <td>This cookie is set by Twitter to identify and track the website visitor.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">syndication_guest_id</td>
                                <td>Used by Twitter to collect information about users' browsing behavior for marketing purposes, including digital display and social media advertising.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">i/adsct</td>
                                <td>The cookie is used by Twitter.com in order to determine the number of visitors accessing the website through teitter advertisment content.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">personalization_id</td>
                                <td>This cookie is set due to Twitter integration and sharing capabilities for the social media.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">rweb_optin</td>
                                <td>Used by twitter.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">muc</td>
                                <td>This cookie is set due to Twitter integration and sharing capabilities for the social media.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">guest_id_marketing</td>
                                <td>Used to detect whether a user is logged into Twitter.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">guest_id_ads</td>
                                <td>This cookie is set due to Twitter integration and sharing capabilities for the social media.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">auth_token</td>
                                <td>This cookie is set due to Twitter integration and sharing capabilities for the social media.</td>
                              </tr>
                              <tr className="w-50">
                                <td className="w-25">ct0</td>
                                <td>This cookie is set due to Twitter integration and sharing capabilities for the social media.</td>
                              </tr>
                            </tbody>
                            </table>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>


            </div>
        </div>
      </div>
    </section>

  </Layout>
  )
}

export const Head = () => <Seo title="Cookies Policy" description="Try the fast-start authorization service which allows to get a multi-tenant authorization system inside of the application." image={openGpaphImage} />

export default CookiesPolicy
